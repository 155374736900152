<hrb-card>
  <hrb-card-content>
    <div class="mb-sp-32">
      <hrb-text style-type="large" style="color: green"
        >Let's get started!</hrb-text
      >
    </div>
    <div class="mb-sp-32">
      <span class="numberWrapper">1</span>&nbsp;
      <hrb-text *ngIf="!isRouteCHJ && !isBestBuyProduct" style-type="small"
        >Enter your activation code.</hrb-text
      >
      <hrb-text *ngIf="isRouteCHJ" style-type="small"
        >Enter your activation code ("license code").</hrb-text
      >
      <hrb-text *ngIf="isBestBuyProduct" style-type="small"
        >Enter your activation code ("your code").</hrb-text
      >
    </div>
    <div class="mb-sp-32">
      <hrb-card theme="light-grey-1">
        <hrb-layout layout="1to3">
          <hrb-card theme="light-grey-1" *ngIf="!isBestBuyProduct">
            <hrb-card-content>
              <img
                style="width: 250px"
                [src]="retailcodePaper"
                alt="download software"
              />
            </hrb-card-content>
          </hrb-card>
          <hrb-card theme="light-grey-1">
            <hrb-card-content>
              <hrb-text
                style-type="little"
                *ngIf="!isRouteCHJ && !isBestBuyProduct"
                >Please enter the 10-character activation code found on the
                instructions sheet that came with your software. Your activation
                code is needed for your installation and gives you access to
                five free federal e-files.</hrb-text
              >
              <hrb-text style-type="little" *ngIf="isRouteCHJ"
                >Please enter your 10-character activation code, referred to as
                your "license code" in the email you received from B&H Photo
                (see image).
                <br />
                <br />
                Your activation code is needed for your installation and gives
                you access to five free federal e-files.
              </hrb-text>

              <hrb-text style-type="little" *ngIf="isBestBuyProduct"
                >{{bestBuyMessage}}
                <br />
                <br />
                Your activation code is needed for your installation and gives
                you access to five free federal e-files.
              </hrb-text>
            </hrb-card-content>
          </hrb-card>
        </hrb-layout>
      </hrb-card>
    </div>
    <hrb-card theme="medium-dark-green">
      <hrb-card-content>
        <div
          class="mb-sp-20"
          style="flex-direction: row; display: flex; justify-content: center"
        >
          <hrb-text style-type="small">Enter your activation code</hrb-text>
        </div>
        <hrb-layout>
          <hrb-card theme="medium-dark-green">
            <form #activationForm="ngForm"></form>
            <hrb-input
              label="10-character activation code"
              name="activationCode"
              mask-pattern="AAAAA-AAAAA"
              maxLength="10"
              #activationCode="ngModel"
              [(ngModel)]="activationCodeDetails.activationCode"
              (keyup)="setActivationCodeValue($event)"
              [class.input-error]="
                (activationCode.touched ||
                  forms.controls.activationCode.touched) &&
                forms.controls.activationCode.errors
              "
            ></hrb-input>
            <hrb-text
              class="error"
              style-type="little"
              style="color: red"
              *ngIf="
                (activationCode.touched ||
                  forms.controls.activationCode.touched) &&
                forms.controls.activationCode.errors?.required
              "
              >{{ MSG.REQUIRED }}</hrb-text
            >
            <hrb-text
              class="error"
              style-type="little"
              style="color: red"
              *ngIf="
                (activationCode.touched ||
                  forms.controls.activationCode.touched) &&
                forms.controls.activationCode.errors?.minlength
              "
              >Please enter valid activation code</hrb-text
            >
          </hrb-card>
        </hrb-layout>
      </hrb-card-content>
    </hrb-card>
  </hrb-card-content>
</hrb-card>
<app-page-not-found>
  
</app-page-not-found>
