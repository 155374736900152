import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {  UntypedFormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActivationCodeDetails } from '../models/activa-code.model';
import { VALIDATIONMSG } from '../models/purchase.model';
import { PurchaseDataService } from '../services/purchase-data.service';
import { PurchaseService } from '../services/purchase.service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-activation-code',
  templateUrl: './activation-code.component.html',
  styleUrls: ['./activation-code.component.scss'],
})
export class ActivationCodeComponent implements OnInit {
  @ViewChild('activationForm', { static: true }) activationForm: NgForm;
  @Input() forms: UntypedFormGroup;

  public retailcodePaper: any;
  public activationCodeDetails = new ActivationCodeDetails();
  public activationCodeValidation: boolean = false;
  public activationCodeForm: UntypedFormGroup;
  public MSG = VALIDATIONMSG;
  public isRouteCHJ = false;
  isBestBuyProduct = false;
  public bestBuyMessage :string =''

  constructor(
    private purchaseDataService: PurchaseDataService,
    private router: ActivatedRoute,
    private purchaseService: PurchaseService
  ) {
    this.activationCodeDetails = {
      activationCode: '',
    };
    this.purchaseService.route.subscribe((route: string) => {
      // set isRouteCHJ to false if does not end with HJ
      this.isRouteCHJ = route.toUpperCase().endsWith('HJ');
    });
  }

  setActivationCodeValue(event: any) {
    this.forms.controls.activationCode.setValue(event.target.value);
  }

  ngOnInit(): void {
    this.activationCodeValidation = false;
    this.router.params.subscribe((params) => {
      let url = document.location.href.split('/');
      let promo = url[url.length - 1];
      let aliasFirstChar = "";
      let aliasLastTwoChars = "";
      if (promo) {
        if (promo.length >= 3) {
          aliasFirstChar = promo[0];
          aliasLastTwoChars  = promo.substring(1); // Get the last two characters

          console.log("aliasFirstChar:", aliasFirstChar);
          console.log("aliasLastTwoChars:", aliasLastTwoChars);
        } else {
          console.error("Input string must have at least 3 characters.");
        }

     
        this.retailcodePaper = 'assets/icons/';
 
        this.promoBasedOnAlias(aliasFirstChar, aliasLastTwoChars)
      }
    });

    if (this.purchaseDataService.actCode.value) {
      this.activationCodeDetails = this.purchaseDataService.actCode.value;
    }

  
  }

  promoBasedOnAlias(aliasFirstChar, aliasLastTwoChars) {
    let aliasFirstCharUpper = aliasFirstChar.toLocaleUpperCase()
    let aliasLastTwoCharsUpper = aliasLastTwoChars.toLocaleUpperCase()



    switch (aliasLastTwoCharsUpper) {

      case 'MD':
      case 'MJ':
        if (aliasFirstCharUpper !== 'C' && aliasFirstCharUpper !== 'D') {
        //Walmart Deluxe and Walmart Premium for 2023
          this.retailcodePaper +=
            `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].GenericInsert}`;
          break;




        }



      case 'RC':
      case 'RD':
      case 'RJ':
      case 'RM':
      case 'RH':
      case 'NJ': {
        this.retailcodePaper +=
          `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].GenericInsert}`;
          
        break;
      }
      case 'BF':
      case 'DF':
      case 'DA':
      case 'PA':
      case 'PH':
      case 'BC':
      case 'BD':
      case 'BJ':
      case 'BM':
      case 'BH':
        {
        this.isBestBuyProduct = true;
        `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].GenericInsert}`;
        this.bestBuyMessage =
          `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].MessageForBestBuy}`;

        break;
      }  // basic
      case 'WJ':
      case 'WC':
      case 'WM':
      case 'WD':
      case 'WH':
      case 'CC':
      case 'CD':
      case 'CJ':
      case 'CM':
      case 'CH':
        this.retailcodePaper +=
        `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].WalmartGiftCard}`;
        break;
      case 'HJ':
        this.retailcodePaper +=
        `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].RetailWrapper_BH_Email}`;
        break;
      default:
        this.retailcodePaper +=
          `${AppConfig.config.aliasFirstCharList[aliasFirstCharUpper].GenericInsert}`;
        break;
    }
  }





    }

 






















